import React, { Component } from 'react';

const skills = [
    { language: "C++", level: 90, text: "proficient", color: "color-2"},
    { language: "JavaScript/TypeScript", level: 94, text: "proficient", color: "color-3"},
    // { language: "C", level: 80, text: "proficient", color: "color-1"},
    { language: "HTML/Vue/React", level: 85, text: "proficient", color: "color-5"},
    { language: "Python", level: 80, text: "proficient", color: "color-4"},
    { language: "CSS", level: 50, text: "advanced knowledge", color: "color-6"},
    { language: "Go", level: 43, text: "experienced", color: "color-8"},
    { language: "Ruby", level: 35, text: "experienced", color: "color-1"},
    { language: "R", level: 30, text: "experienced", color: "color-7"},
];

export default class Skills extends Component {
    fadeInDirection(idx) {
        return (idx % 2) ? "fadeInRight" : "fadeInLeft";
    }

    render() {
        return (
            <div>
                <section className="colorlib-skills" data-section="programming">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">Programming</span>
                                <h2 className="colorlib-heading animate-box">Programming Languages</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                                <p>As a Software Developer, I have gained experience in various programming languages throughout my career. Below you will find a list of programming languages that I am proficient in. I am always excited to take on new challenges and projects to expand my skillset.</p>
                            </div>
                            {skills.map((skill, idx) => (
                                <div key={`skill-${idx}`} className="col-md-6 animate-box" data-animate-effect={this.fadeInDirection(idx)}>
                                    <div className="progress-wrap">
                                        <h3>{skill.language}</h3>
                                        <div className="progress">
                                            <div className={`progress-bar ${skill.color}`} role="progressbar" aria-valuenow={skill.level}
                                            aria-valuemin="0" aria-valuemax="100" style={{"width": `${skill.level}%`}}>
                                            <span>{skill.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
