import React, { Component } from 'react';

import InfoCards from './elements/infoCards';

const skills = [
  {
    id: 1,
    title: "Web Development",
    icon: "icon-html-five",
    color: "color-1",
    description: "I have experience building websites using JavaScript, Vue/React, HTML, and CSS. I built personal websites (like this one) but was also part of bigger software projects."
  },
  {
    id: 2,
    title: "Data Science & Research",
    icon: "icon-data",
    color: "color-3",
    description: "I am familiar with data science and data analysis. I mainly use Python in a Jupyter Notebook environment in combination with Pandas for Data Analysis and visualization of collected data."
  },
  {
    id: 3,
    title: "Game Development",
    icon: "bi-controller",
    color: "color-5",
    description: "During high-school and university I got the opportunity to develop a few games on my own or in cooperation with other students. They include an online multiplayer game, a game for blind people and another game that is focused on software patterns."
  },
  {
    id: 4,
    title: "Github",
    icon: "icon-github",
    color: "color-2",
    description: "I use Github in all of my projects to synchronize and control my work.I also utilize Github Actions for CI/CD and developed custom Github Apps."
  },
  {
    id: 5,
    title: "Agile Software Development",
    icon: "bi-code-slash", 
    color: "color-4",
    description: "I have experience in Agile Software Development and the Software Development Life Cycle. I worked as a developer in a large team and also managed projects on my own."
  },
  {
    id: 6,
    title: "Backend development",
    icon: "bi-hdd-stack",
    color: "color-6",
    description: "I have developed backend services in C++, Python, and TypeScript. I have experience in developing RESTful APIs and using database integrations with PostgreSQL and HANA."
  },
];

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    <span className="heading-meta">About Me</span>
                    <h2 className="colorlib-heading">Who Am I?</h2>
                    <p>I am an IT-Systems Engineering student from Germany. I love to code projects on my own to improve my programming skills.</p>
                    <p>In the last years I created several projects in the fields of web-development, server backends, electric vehicles and more. </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                        <span className="heading-meta">What I do?</span>
                        <h2 className="colorlib-heading">My expertise</h2>
                    </div>
                </div>
                <InfoCards items={skills}></InfoCards>
            </div>
        </section>
      </div>
    )
  }
}
